import { Empty, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  ActionWrapper,
  BodyModalContentWrapper,
  BodyModalInfoWrapper,
  BodyModalWrapper,
  ButtonInTable,
  ButtonWrapper,
  ModalInfoWrapper,
  ModalLevelMasterWrapper,
  ModalWrapper,
  ShowMoreText,
  StyledButton,
  StyledCheckbox,
} from './styled';
import { AdminPanelTable } from 'components/Table/styled';
import {
  ISecurityFlagDetail,
  useGetSecurityFlagByPlayerId,
} from 'hooks/security-flag/useGetSecurityFlagByPlayerId';
import { downloadFlagGameSession, updateFlagStatus } from 'services/api';
import { toast } from 'react-toastify';
import { SessionUpdateDTO } from 'services/api/dto/updateFlagStatus.dto';

export const SecurityFlagDetail = ({
  playerId,
  refreshFlaggedPlayer,
  setRefreshFlaggedPlayer,
}: {
  playerId: string;
  refreshFlaggedPlayer: boolean;
  setRefreshFlaggedPlayer: (value) => void;
}) => {
  const open = useModalIsOpen(ApplicationModal.SECURITY_FLAG_DETAIL);
  const toggleModal = useToggleModal(ApplicationModal.SECURITY_FLAG_DETAIL);
  const pageSizeDefault = 50;
  const [pageSize, setPageSize] = useState<number>(pageSizeDefault);
  const [params, setParams] = useState({
    pageSize: pageSizeDefault,
    pageIndex: 1,
    sortField: 'createdAt',
    sortDirection: 'desc',
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [flagList, setFlagList] = useState<SessionUpdateDTO[]>([]);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { loading, securityFlagList, total } = useGetSecurityFlagByPlayerId(
    playerId,
    params,
    refresh,
  );

  const handleCancel = () => {
    setPageSize(pageSizeDefault);
    if (params.pageSize !== pageSizeDefault)
      setParams({
        ...params,
        pageSize: pageSizeDefault,
      });
    toggleModal();
  };

  const handleCheckbox = (record: ISecurityFlagDetail, event: any) => {
    setFlagList(prevFlagList =>
      prevFlagList.map(item =>
        item.sessionId === record.sessionId && item.gameId === record.gameId
          ? { ...item, isGuilty: event.target.checked }
          : item,
      ),
    );
  };

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: value => value + 1,
    },
    {
      title: 'Game',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: name => <div>{name}</div>,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      render: level => <div>{level}</div>,
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: createdAt => (
        <div>{new Date(createdAt).toLocaleString('en-AU')}</div>
      ),
    },
    {
      title: 'Guilty',
      dataIndex: 'isGuilty',
      key: 'isGuilty',
      render: (_, record) => (
        <div>
          <StyledCheckbox onChange={e => handleCheckbox(record, e)} />
        </div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'action',
      render: (_, record, index) => (
        <ActionWrapper>
          <Spin spinning={downloadLoading}>
            <ButtonInTable
              onClick={() => {
                handleDownload(record);
              }}
            >
              Download
            </ButtonInTable>
          </Spin>
        </ActionWrapper>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setParams({
      ...params,
      sortField: sorter.field && sorter.order ? sorter.field : undefined,
      sortDirection: sorter.order
        ? sorter.order === 'descend'
          ? 'desc'
          : 'asc'
        : undefined,
    });
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      const data = {
        playerId: playerId,
        sessions: flagList,
      };
      const response = await updateFlagStatus(data);
      if (response?.success) {
        toast.success('Update status success!!');
        setRefreshFlaggedPlayer(!refreshFlaggedPlayer);
      } else toast.error(response?.message);
    } catch (error) {
      toast.error('An error occurred while updating the status.');
      console.log(error);
    } finally {
      setSubmitLoading(false);
      setPageSize(pageSizeDefault);
      if (params.pageSize !== pageSizeDefault)
        setParams({
          ...params,
          pageSize: pageSizeDefault,
        });
      toggleModal();
    }
  };

  const handleDownload = async (record: any) => {
    setDownloadLoading(true);
    try {
      const data = {
        playerId: playerId,
        level: record.level,
        gameId: record.gameId,
      };
      const response = await downloadFlagGameSession(data);
      if (response?.success) {
        toast.success('Download flagged game session success!!');
      } else toast.error(response?.message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    if (securityFlagList) {
      const checkboxList = securityFlagList.map(item => ({
        sessionId: item.sessionId,
        gameId: item.gameId,
        isGuilty: false,
      }));
      setFlagList(checkboxList);
    }
  }, [securityFlagList]);

  return (
    <ModalWrapper
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      width="646px"
      destroyOnClose={true}
    >
      <ModalLevelMasterWrapper>
        <BodyModalWrapper>
          <BodyModalInfoWrapper>
            <ModalInfoWrapper>Flagged game detail</ModalInfoWrapper>
          </BodyModalInfoWrapper>

          <Spin spinning={loading}>
            <BodyModalContentWrapper isEmpty={!securityFlagList?.length}>
              {securityFlagList?.length ? (
                <>
                  <AdminPanelTable
                    dataSource={securityFlagList?.map((item, key) => ({
                      ...item,
                      key,
                    }))}
                    columns={columns}
                    pagination={false}
                    loading={loading}
                    onChange={handleTableChange}
                  />
                  {total > pageSize && (
                    <Row justify="end">
                      <ShowMoreText
                        onClick={() => {
                          setPageSize(pageSize + 50);
                          setParams({
                            ...params,
                            pageSize: pageSize + 50,
                          });
                          setRefresh(!refresh);
                        }}
                      >
                        Show more
                      </ShowMoreText>
                    </Row>
                  )}
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </BodyModalContentWrapper>
          </Spin>
          <ButtonWrapper>
            <Spin spinning={submitLoading}>
              <StyledButton onClick={handleSubmit}>Submit</StyledButton>
            </Spin>
          </ButtonWrapper>
        </BodyModalWrapper>
      </ModalLevelMasterWrapper>
    </ModalWrapper>
  );
};
