import { Empty, Form, Spin, Switch } from 'antd';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-icon.svg';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import { ReactComponent as PlusIcon } from 'assets/images/profile/plus-ellipse.svg';
import { ReactComponent as SubIcon } from 'assets/images/profile/sub-ellipse.svg';
import { ReactComponent as MetamaskLogo } from 'assets/images/profile/metamask-logo.svg';
import { ReactComponent as GeminiLogo } from 'assets/images/profile/gemini-logo.svg';
import { ConfirmDelete } from 'components/Modals/UserProfile/DeleteAccount/ConfirmDelete';
import { LevelMasters } from 'components/Modals/UserProfile/LevelMasters';
import { UploadAvatar } from 'components/Modals/UserProfile/UploadAvatar';
import { VerifyOtp } from 'components/Modals/UserProfile/VerifyOTP';
import { GameSetting } from 'constants/enum/GameSetting';
import { formatUnits } from 'ethers/lib/utils';
import { useGetNotificationSetting } from 'hooks/notification-setting/useGetNotificationSetting';
import useEnableNotification from 'hooks/notification/useEnableNotification';
import { useGetPlayerGameRevenueListForScroll } from 'hooks/profile/useGetPlayerGameRevenueListForScroll';
import { useGetPlayerGameStatistics } from 'hooks/profile/useGetPlayerGameStatistics';
import { useGetRemainingBalance } from 'hooks/token-balance/useGetRemainingBalance';
import useOneSignal from 'hooks/useOneSignal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import { loginUser } from 'redux/slices/player';
import { useAppSelector } from 'redux/store';
import { ReactComponent as PasswordIcon } from 'assets/icons/lock.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye-show.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye-off.svg';
import { ReactComponent as UserIcon } from 'assets/icons/user-outline.svg';
import { ReactComponent as MailIcon } from 'assets/icons/mail-icon.svg';
import {
  ILoginByWallet,
  changePassword,
  setupProfile,
  updateNotificationSetting,
  updatePlayerEmail,
  updateWallet,
} from 'services/api';
import HomepageTemplate from 'template/Homepage';
import { LocalhostStorage } from 'utils/sessionStorage';
import Web3 from 'web3';
import { ReactComponent as Plus } from 'assets/icons/plus-icon.svg';
import {
  AddButton,
  Avatar,
  AvatarWrapper,
  BIGAInfoWrapper,
  BIGAValue,
  BalanceText,
  CollapseSaveButton,
  CollapseSaveButtonWrapper,
  ConnectButton,
  DeleteButton,
  DeleteButtonWrapper,
  EditIcon,
  EmailText,
  FormItemWrapper,
  FormWrapper,
  GameDetailRow,
  GameStasItem,
  Gradient1,
  Gradient2,
  Gradient3,
  InfoLeftWrapper,
  InfoRightWrapper,
  InfoRightWrapperForScroll,
  InfoTextWrapper,
  InfoTitle,
  InfoTitleText,
  InfoWrapper,
  LevelWrapper,
  LinkWrapper,
  LoadMoreButtonWrapper,
  LoadMoreText,
  LogoutMobileWrapper,
  MetamaskText,
  PageTitle,
  ProfileAvatarWrapper,
  ProfileContentWrapper,
  ProfileInfoWrapper,
  ProfileWrapper,
  RevenueHistoryWrapper,
  RevenueValue,
  ShowMoreButton,
  StyledDivider,
  StyledInput,
  UnderlineText,
  Username,
  UsernameText,
  WinLabel,
  WithdrawWrapper,
} from './styled';
import { useLogOut } from 'hooks/useLogOut';
import { UpdateInformationModal } from 'components/Modals/UserProfile/UpdateInformationModal';
import { LogoutModal } from 'components/Modals/UserProfile/LogoutModal';
import { useNavigate } from 'react-router-dom';
import { DisconnectMetamask } from 'components/Modals/UserProfile/DisconnectMetamask';
import { useAccount, useSignMessage } from 'wagmi';
import useWagmiConnectors from 'hooks/useWagmiConnectors';
import { useCheckConnectedWallet } from 'hooks/player/useCheckConnectedWallet';
import { ChangeWalletModal } from 'components/Modals/ChangeWallet';

export const Profile = () => {
  const { address, isConnected: active, chainId } = useAccount();
  const { signMessage: signMessageWagmi, reset } = useSignMessage();
  const { onConnectWallet, onDisconnect } = useWagmiConnectors();
  const [isUsernameEditing, setIsUsernameEditing] = useState(false);
  const [isEmailEditing, setIsEmailEditing] = useState(false);
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);
  const [isOpenDisconnectMetamaskModal, setIsOpenDisconnectMetamaskModal] =
    useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const { playerInfo, refreshAvatar } = useAppSelector(state => state.player);
  const logOut = useLogOut();
  const [account, setAccount] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMetamaskClick, setIsMetamaskClick] = useState<boolean>(false);
  const [isOpenChangeWalletModal, setIsOpenChangeWalletModal] =
    useState<boolean>(false);
  const [isConfirmedChangeWallet, setIsConfirmedChangeWallet] =
    useState<boolean>(false);

  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const dispatch = useDispatch();

  const handleUsernameEditClick = () => {
    setIsUsernameEditing(prevIsUsernameEditing => !prevIsUsernameEditing);
  };

  const handleEmailEditClick = () => {
    setIsEmailEditing(prevIsEmailEditing => !prevIsEmailEditing);
  };

  const handleCancelUsernameClick = () => {
    setIsUsernameEditing(false);
  };

  const handleCancelEmailClick = () => {
    setIsEmailEditing(false);
  };

  useEffect(() => {
    if (!address) {
      setAccount('');
      setIsConnected(undefined);
      return;
    }
    if (playerInfo && address === playerInfo.walletAddress) {
      setAccount(address);
      setLoading(false);
    } else {
      setAccount('');
    }
  }, [address, playerInfo]);

  const handleSaveUsernameClick = async () => {
    try {
      setUsernameUpdating(true);

      const formData = new FormData();
      formData.append('username', username);

      const res = await setupProfile(formData);
      if (res?.success) {
        setUsername('');
        setIsUsernameEditing(false);
        const updatedPlayerInfo = { ...playerInfo, username: username };
        dispatch(loginUser(updatedPlayerInfo));
        toast.success('Update username successfully');
      } else {
        toast.error(res?.message || 'Update username failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUsernameUpdating(false);
    }
  };

  const onUpdatedEmail = async () => {
    const updatedPlayerInfo = { ...playerInfo, emailAddress: email };
    dispatch(loginUser(updatedPlayerInfo));
    toast.success('Update email successfully');
    setEmail('');

    toggleVerifyOtpModal();
    handleCancelEmailClick();
  };

  const toggleUploadAvatarModal = useToggleModal(
    ApplicationModal.PROFILE_UPLOAD_AVATAR,
  );
  const showAvatarModal = () => {
    toggleUploadAvatarModal();
  };

  const toggleLevelMastersModal = useToggleModal(
    ApplicationModal.PROFILE_LEVEL_MASTERS,
  );
  const showLevelMastersModal = () => {
    toggleLevelMastersModal();
  };

  const toggleDeleteAccountModal = useToggleModal(
    ApplicationModal.PROFILE_CONFIRM_DELETE_ACCOUNT,
  );
  const showDeleteAccountModal = () => {
    toggleDeleteAccountModal();
  };

  const toggleVerifyOtpModal = useToggleModal(
    ApplicationModal.PROFILE_VERIFY_OTP,
  );
  const showVerifyOtpModal = async () => {
    try {
      setEmailUpdating(true);

      const res = await updatePlayerEmail({ emailAddress: email });
      if (res?.success) {
        toggleVerifyOtpModal();
      } else {
        toast.error(res?.message || 'Update email failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setEmailUpdating(false);
    }
  };

  const [refresh, setRefresh] = useState<boolean>(false);
  const [usernameUpdating, setUsernameUpdating] = useState<boolean>(false);
  const [emailUpdating, setEmailUpdating] = useState<boolean>(false);
  const [params, setParams] = useState({
    pageSize: 5,
    pageIndex: 1,
    isCurrentMaster: false,
    isRecent: true,
  });

  const handleRefresh = () => {
    setRefresh(prevRefresh => !prevRefresh);
  };

  const { playerGameStatistics } = useGetPlayerGameStatistics(null, refresh);

  const { remainingBalance } = useGetRemainingBalance(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
  );

  const {
    playerGameRevenueList,
    loading: playerGameRevenueListLoading,
    total: playerGameRevenueListTotal,
  } = useGetPlayerGameRevenueListForScroll(params, refresh);

  const avatarURL = useMemo(() => {
    return playerInfo?.avatarURL
      ? `${playerInfo?.avatarURL}?timestamp=${Date.now()}`
      : '';
  }, [playerInfo?.avatarURL, refreshAvatar]);

  const { notificationSettingList } = useGetNotificationSetting();
  const [notificationSettings, setNotificationSettings] = useState([]);
  const { getSubscriptionID, checkInitOneSignal } = useOneSignal();
  const isEnableNotificateion = useEnableNotification();
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const navigate = useNavigate();

  const [addressParams, setAddressParams] = useState<{
    walletAddress?: string;
  }>({
    walletAddress: address,
  });
  const {
    isConnected,
    loading: checkLoading,
    setIsConnected,
  } = useCheckConnectedWallet(addressParams, addressParams);

  useEffect(() => {
    if (notificationSettingList.length > 0) {
      setNotificationSettings(notificationSettingList);
    }
    // else {
    //   const defaultSettings = Object.values(GameSetting).map(type => ({
    //     type,
    //     enabled: false,
    //   }));

    //   updateNotificationSetting(defaultSettings);
    //   setNotificationSettings(defaultSettings);
    // }
  }, [notificationSettingList]);

  const handleNotificationToggle = async (type, checked) => {
    const updatedSettings = notificationSettings.map(setting => {
      if (setting.type === type) {
        return { ...setting, enabled: checked };
      }
      return setting;
    });

    setNotificationSettings(updatedSettings);
    const resUpdate = await updateNotificationSetting(updatedSettings);
    if (resUpdate?.success) {
      const DesktopNotification = resUpdate?.payload?.data
        .filter(item => item.type === GameSetting.DesktopNotification)
        .map(item => item.enabled)[0];
    }
  };

  const getNotificationTitle = type => {
    switch (type) {
      case GameSetting.EmailNotification:
        return 'Email Notification';
      case GameSetting.DesktopNotification:
        return 'Desktop Notification';
      default:
        return 'Unknown Notification';
    }
  };

  useEffect(() => {
    if (active && address) {
      setAddressParams({ walletAddress: address });
    }
  }, [active, address]);

  const onSignMessageSuccess = async (data: any, variables: any) => {
    const linkWalletParams: ILoginByWallet = {
      message: String(variables?.message),
      signature: data,
      walletAddress: address,
    };
    const linkWalletCall = await updateWallet(linkWalletParams);
    if (!linkWalletCall.success) {
      toast.error(linkWalletCall.message);
      setLoading(false);
      setIsMetamaskClick(false);
      onDisconnect();
      setIsOpenChangeWalletModal(false);
      setIsConfirmedChangeWallet(false);
      return;
    }
    const updatedPlayerInfo = {
      ...playerInfo,
      walletAddress: address,
    };
    dispatch(loginUser(updatedPlayerInfo));
    handleRefresh();
    LocalhostStorage.set('account', account);
    toast.success('Change wallet address success');
    setLoading(false);
    setIsMetamaskClick(false);
    setIsOpenChangeWalletModal(false);
    setIsConfirmedChangeWallet(false);
  };

  const onSignMessageError = async () => {
    toast.error('User denied message signature.');
    setLoading(false);
    setIsMetamaskClick(false);
    onDisconnect();
    reset();
    setIsConfirmedChangeWallet(false);
    setIsOpenChangeWalletModal(false);
    return;
  };

  const handleChangeAccount = useCallback(async () => {
    if (isMetamaskClick) {
      if (!address) {
        setAccount('');
        return;
      }

      if (
        address &&
        !checkLoading &&
        isConnected === false &&
        playerInfo &&
        playerInfo.walletAddress &&
        address !== playerInfo.walletAddress &&
        loading
      ) {
        setIsOpenChangeWalletModal(true);
      }

      if (
        address &&
        !checkLoading &&
        playerInfo &&
        playerInfo.walletAddress &&
        address !== playerInfo.walletAddress
      ) {
        if (isConnected === true) {
          toast.error(
            'The wallet address has been already connected to another player',
          );
          onDisconnect();
          setAccount('');
          setLoading(false);
          setIsConnected(undefined);
          setIsMetamaskClick(false);
          return;
        }
      }

      if (
        address &&
        !checkLoading &&
        playerInfo &&
        !playerInfo.walletAddress &&
        (isConnected === true || isConnected === false)
      ) {
        setLoading(false);
        setIsMetamaskClick(false);
        return;
      }

      if (
        isConfirmedChangeWallet &&
        address &&
        !checkLoading &&
        isConnected === false &&
        playerInfo &&
        playerInfo.walletAddress &&
        address !== playerInfo.walletAddress &&
        loading
      ) {
        const typeOfConnector = LocalhostStorage.get('typeOfConnector');
        if (typeOfConnector && typeOfConnector === 'Metamask') {
          await signMessageWagmi(
            {
              account: address,
              message: `If you click the 'sign' button, you agree to authorize us to make this transaction.
            Your Wallet Address: ${address}
            Timestamp: ${Date.now()}
           `,
            },
            {
              onSuccess: onSignMessageSuccess,
              onError: onSignMessageError,
            },
          );
        } else {
          return;
        }
      }
    }
  }, [
    address,
    playerInfo,
    checkLoading,
    isConnected,
    loading,
    isConfirmedChangeWallet,
  ]);

  useEffect(() => {
    handleChangeAccount();
  }, [handleChangeAccount]);

  const submitChangePassword = async () => {
    try {
      toast.info('Wait for updating password...');
      const res = await changePassword({
        newPassword,
        oldPassword: currentPassword,
      });
      if (res?.success && res?.payload?.data) {
        toast.dismiss();
        toast.success('Change password successfully!');
      } else {
        toast.dismiss();
        toast.error(res?.message || 'Change password failed');
      }
    } catch (error) {
      toast.error('Change password failed');
      console.log(error);
    }
  };

  const disconnectMetamask = () => {
    onDisconnect();
    setIsOpenDisconnectMetamaskModal(false);
  };

  useEffect(() => {
    if (playerInfo && playerInfo.username) setUsername(playerInfo.username);
  }, [playerInfo]);

  const onConfirmChangeWallet = () => {
    setIsConfirmedChangeWallet(true);
  };

  const handleConnectMetamask = () => {
    setLoading(true);
    setIsMetamaskClick(true);
    onConnectWallet({
      onError: () => {
        setLoading(false);
        setIsMetamaskClick(false);
      },
    });
  };

  return (
    <HomepageTemplate>
      <ProfileWrapper>
        <ProfileContentWrapper>
          <ProfileInfoWrapper>
            <InfoLeftWrapper>
              <PageTitle>Account Management</PageTitle>
              <ProfileAvatarWrapper>
                <AvatarWrapper>
                  <Avatar
                    src={avatarURL ? avatarURL : defaultProfileImg}
                    alt="avatar profile"
                    onClick={showAvatarModal}
                  />
                  <UploadAvatar onRefresh={handleRefresh} />
                  <EditIcon />
                </AvatarWrapper>
                <Username>{playerInfo?.username || 'Username'}</Username>
              </ProfileAvatarWrapper>
              <LinkWrapper
                onClick={() => setIsOpenLogoutModal(!isOpenLogoutModal)}
              >
                Logout <LogoutIcon />
              </LinkWrapper>
            </InfoLeftWrapper>

            <InfoRightWrapper>
              <InfoWrapper>
                <InfoTitle>Personal information</InfoTitle>
                <FormWrapper>
                  <InfoTextWrapper>
                    <InfoTitleText className="margin-bottom-10px">
                      Username
                    </InfoTitleText>
                    <UsernameText onClick={handleUsernameEditClick}>
                      {playerInfo?.username || 'Username'}
                    </UsernameText>
                  </InfoTextWrapper>

                  <UpdateInformationModal
                    isOpen={isUsernameEditing}
                    onCancel={handleCancelUsernameClick}
                  >
                    <Spin spinning={usernameUpdating}>
                      <Form
                        onFinish={handleSaveUsernameClick}
                        autoComplete="off"
                        disabled={usernameUpdating}
                      >
                        <FormItemWrapper>
                          <InfoTitleText className="font-size-22px white">
                            Username
                          </InfoTitleText>
                          <Form.Item
                            name="username"
                            rules={[
                              {
                                min: 6,
                                message:
                                  'Username must be minimum 6 characters',
                              },
                              {
                                max: 20,
                                message:
                                  'Username has a maximum of 20 characters',
                              },
                              {
                                pattern: new RegExp(
                                  '^[A-Za-z]*[A-Za-z][a-zA-Z0-9_.]{5,19}$',
                                ),
                                message:
                                  'Username must start with a letter. Allowed \ncharacters are a-z (both lower case and upper case), \n0-9, _(underscore), and .(dot)',
                              },
                            ]}
                            validateFirst={true}
                          >
                            <StyledInput
                              autoComplete="off"
                              type="text"
                              placeholder="Username"
                              value={username}
                              onChange={handleUsernameChange}
                              prefix={<UserIcon />}
                            />
                          </Form.Item>
                          <CollapseSaveButtonWrapper className="modal-btn-wrapper">
                            <CollapseSaveButton
                              htmlType="submit"
                              disabled={usernameUpdating || !username}
                              className="modal-btn"
                            >
                              Save
                            </CollapseSaveButton>
                          </CollapseSaveButtonWrapper>
                        </FormItemWrapper>
                      </Form>
                    </Spin>
                  </UpdateInformationModal>

                  <InfoTextWrapper>
                    <InfoTitleText className="margin-bottom-10px">
                      Email address
                    </InfoTitleText>
                    <EmailText onClick={handleEmailEditClick}>
                      {playerInfo?.emailAddress || 'Email address'}
                    </EmailText>
                  </InfoTextWrapper>

                  <UpdateInformationModal
                    isOpen={isEmailEditing}
                    onCancel={handleCancelEmailClick}
                  >
                    <Spin spinning={emailUpdating}>
                      <Form
                        onFinish={showVerifyOtpModal}
                        autoComplete="off"
                        disabled={emailUpdating}
                      >
                        <FormItemWrapper>
                          <InfoTitleText className="font-size-22px white">
                            Email Address
                          </InfoTitleText>
                          <Form.Item
                            name="email"
                            rules={[
                              {
                                pattern: new RegExp(
                                  '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$',
                                ),
                                message: 'Invalid email',
                              },
                            ]}
                          >
                            <StyledInput
                              type="text"
                              placeholder="Email address"
                              disabled={emailUpdating}
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                              prefix={<MailIcon />}
                            />
                          </Form.Item>
                          <CollapseSaveButtonWrapper className="modal-btn-wrapper">
                            <CollapseSaveButton
                              htmlType="submit"
                              disabled={emailUpdating || !email}
                              className="modal-btn"
                            >
                              Save
                            </CollapseSaveButton>
                          </CollapseSaveButtonWrapper>
                          <VerifyOtp
                            waitToVerifyEmailAddress={email}
                            onSuccess={onUpdatedEmail}
                          />
                        </FormItemWrapper>
                      </Form>
                    </Spin>
                  </UpdateInformationModal>

                  <Form
                    onFinish={submitChangePassword}
                    autoComplete="off"
                    disabled={true}
                  >
                    <FormItemWrapper>
                      <InfoTitleText>Change Password</InfoTitleText>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            min: 8,
                            message: 'Password must be minimum 8 characters.',
                          },
                          {
                            max: 30,
                            message: 'Password must be maximum 30 characters.',
                          },
                          {
                            validator: (_, value, callback) => {
                              if (value && !new RegExp('[0-9]').test(value)) {
                                callback(
                                  'Password must contains at least one number.',
                                );
                              } else {
                                callback();
                              }
                            },
                          },
                          {
                            validator: (_, value, callback) => {
                              if (
                                value &&
                                !new RegExp('[a-zA-Z]').test(value)
                              ) {
                                callback(
                                  'Password must contains at least one alphabet.',
                                );
                              } else {
                                callback();
                              }
                            },
                          },
                          {
                            validator: (_, value, callback) => {
                              if (
                                value &&
                                !new RegExp('[!@#$%^&*()+=_-]').test(value)
                              ) {
                                callback(
                                  'Password must contain at least one special character \n from the following: !@#$%^&*()+=_-',
                                );
                              } else {
                                callback();
                              }
                            },
                          },
                          {
                            validator: (_, value, callback) => {
                              if (
                                value &&
                                !/^[^<>?,."|~`';:{}[\]/\\]+$/.test(value)
                              ) {
                                callback(
                                  'Password must not contain special characters \n from the following : <,>.?/|\\~`:;"\'{[]} ',
                                );
                              } else {
                                callback();
                              }
                            },
                          },
                          {
                            validator: (_, value, callback) => {
                              if (value && new RegExp('\\s+').test(value)) {
                                callback(
                                  'Password must not contain any white space.',
                                );
                              } else {
                                callback();
                              }
                            },
                          },
                        ]}
                        validateFirst
                      >
                        <StyledInput
                          onChange={e => setNewPassword(e.target.value)}
                          type={newPasswordVisible ? 'text' : 'password'}
                          placeholder="New Password"
                          prefix={<PasswordIcon />}
                          suffix={
                            newPasswordVisible ? (
                              <EyeIcon
                                onClick={() =>
                                  setNewPasswordVisible(!newPasswordVisible)
                                }
                                className="fill-white"
                              />
                            ) : (
                              <EyeOffIcon
                                onClick={() =>
                                  setNewPasswordVisible(!newPasswordVisible)
                                }
                              />
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item name="currentPassword">
                        <StyledInput
                          onChange={e => setCurrentPassword(e.target.value)}
                          type={currentPasswordVisible ? 'text' : 'password'}
                          placeholder="Current Password"
                          prefix={<PasswordIcon />}
                          suffix={
                            currentPasswordVisible ? (
                              <EyeIcon
                                onClick={() =>
                                  setCurrentPasswordVisible(
                                    !currentPasswordVisible,
                                  )
                                }
                                className="fill-white"
                              />
                            ) : (
                              <EyeOffIcon
                                onClick={() =>
                                  setCurrentPasswordVisible(
                                    !currentPasswordVisible,
                                  )
                                }
                              />
                            )
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        className="mb-2"
                        name="re-pass"
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('Password doesn’t match'),
                              );
                            },
                          }),
                        ]}
                        dependencies={['password']}
                      >
                        <StyledInput
                          onChange={e => setConfirmPassword(e.target.value)}
                          prefix={<PasswordIcon />}
                          type={confirmPasswordVisible ? 'text' : 'password'}
                          placeholder="Confirm New Password"
                          suffix={
                            confirmPasswordVisible ? (
                              <EyeIcon
                                onClick={() =>
                                  setConfirmPasswordVisible(
                                    !confirmPasswordVisible,
                                  )
                                }
                                className="fill-white"
                              />
                            ) : (
                              <EyeOffIcon
                                onClick={() =>
                                  setConfirmPasswordVisible(
                                    !confirmPasswordVisible,
                                  )
                                }
                              />
                            )
                          }
                        />
                      </Form.Item>
                    </FormItemWrapper>

                    <CollapseSaveButtonWrapper>
                      <CollapseSaveButton
                        htmlType="submit"
                        disabled={
                          !currentPassword || !newPassword || !confirmPassword
                        }
                      >
                        Save
                      </CollapseSaveButton>
                    </CollapseSaveButtonWrapper>
                  </Form>
                </FormWrapper>
              </InfoWrapper>

              <InfoWrapper>
                <InfoTitle>Connected wallet</InfoTitle>

                <FormWrapper>
                  <InfoTextWrapper className="game-name-wrapper">
                    <InfoTitleText className="white font-weight-400-sm">
                      Connected
                    </InfoTitleText>
                    {account ? (
                      <>
                        <ConnectButton
                          className="black"
                          onClick={() => setIsOpenDisconnectMetamaskModal(true)}
                        >
                          <MetamaskLogo />
                          <MetamaskText />
                          <SubIcon />
                        </ConnectButton>
                        <DisconnectMetamask
                          isOpen={isOpenDisconnectMetamaskModal}
                          onCancel={() =>
                            setIsOpenDisconnectMetamaskModal(false)
                          }
                          onOk={disconnectMetamask}
                        />
                      </>
                    ) : (
                      <Spin spinning={loading} delay={500}>
                        <ConnectButton
                          className="black"
                          onClick={handleConnectMetamask}
                          disabled={loading}
                        >
                          <MetamaskLogo />
                          <MetamaskText />
                          <PlusIcon />
                        </ConnectButton>
                      </Spin>
                    )}
                  </InfoTextWrapper>
                  <InfoTextWrapper className="game-name-wrapper">
                    <InfoTitleText className="font-size-16px font-weight-400-sm">
                      Wallet address
                    </InfoTitleText>
                    <InfoTitleText className="white font-size-16px font-weight-400-sm">
                      {account && playerInfo?.walletAddress
                        ? playerInfo?.walletAddress
                        : 'No wallet address is connected'}
                    </InfoTitleText>
                  </InfoTextWrapper>
                  <InfoTextWrapper className="game-name-wrapper">
                    <InfoTitleText className="font-size-16px font-weight-400-sm">
                      Connect wallet
                    </InfoTitleText>
                    <ConnectButton onClick={() => toast.error('Coming soon!')}>
                      <GeminiLogo />
                      <PlusIcon />
                    </ConnectButton>
                  </InfoTextWrapper>
                </FormWrapper>
              </InfoWrapper>

              <InfoWrapper>
                <InfoTitle>Withdraw</InfoTitle>
                <FormWrapper>
                  <InfoTextWrapper>
                    <InfoTitleText className="white font-weight-400-sm">
                      Wallet balance
                    </InfoTitleText>
                    <BalanceText className="balance">
                      {remainingBalance?.amount ? (
                        <>
                          {parseFloat(
                            formatUnits(
                              remainingBalance.amount,
                              remainingBalance.tokenInfo?.decimals || 18,
                            ),
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                          {' ($'}
                          {(
                            parseFloat(
                              formatUnits(
                                remainingBalance.amount,
                                remainingBalance.tokenInfo?.decimals || 18,
                              ),
                            ) / 10
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                          {')'}
                        </>
                      ) : (
                        0
                      )}
                    </BalanceText>
                  </InfoTextWrapper>
                  <WithdrawWrapper>
                    <InfoTitleText className="white font-weight-400-sm">
                      Withdraw
                    </InfoTitleText>
                    <AddButton onClick={() => navigate('/cashier')}>
                      <Plus />
                    </AddButton>
                  </WithdrawWrapper>
                </FormWrapper>
              </InfoWrapper>

              <InfoWrapper>
                <InfoTitle>Game Stats</InfoTitle>
                <FormWrapper className="game-stats-wrapper">
                  <GameStasItem>
                    <InfoTitleText className="white">Game played</InfoTitleText>
                    <BIGAValue>
                      {playerGameStatistics?.gamesPlayed || 0}
                    </BIGAValue>
                  </GameStasItem>
                  <GameStasItem>
                    <InfoTitleText className="white">Biga Spent</InfoTitleText>
                    <BIGAValue>
                      {playerGameStatistics?.spentAmount?.amount
                        ? parseFloat(
                            formatUnits(
                              playerGameStatistics?.spentAmount?.amount,
                              playerGameStatistics?.spentAmount?.decimals,
                            ),
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </BIGAValue>
                  </GameStasItem>
                  <GameStasItem>
                    <InfoTitleText className="white">
                      Revenue Breakdown
                    </InfoTitleText>
                    {/* <BIGAValue>
                    {playerGameStatistics?.currentRevenueCount || 0}
                  </BIGAValue> */}
                    <UnderlineText onClick={showLevelMastersModal}>
                      Details
                    </UnderlineText>
                  </GameStasItem>
                </FormWrapper>
                <LevelMasters />
              </InfoWrapper>

              <InfoWrapper>
                <RevenueHistoryWrapper>
                  <GameStasItem className="revenue-history">
                    <InfoTitle>Revenue History</InfoTitle>
                    <FormWrapper>
                      <InfoTextWrapper className="game-name-wrapper">
                        <InfoTitleText className="white font-weight-400-sm">
                          Total Revenue
                        </InfoTitleText>
                        <RevenueValue>
                          {playerGameStatistics?.revenueAmount?.amount ? (
                            <>
                              {parseFloat(
                                formatUnits(
                                  playerGameStatistics?.revenueAmount?.amount,
                                  playerGameStatistics?.revenueAmount
                                    ?.decimals || 18,
                                ),
                              )
                                .toLocaleString('de-DE', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  useGrouping: true,
                                })
                                .replace(/\./g, ' ')}
                              {' ($'}
                              {(
                                parseFloat(
                                  formatUnits(
                                    playerGameStatistics?.revenueAmount?.amount,
                                    playerGameStatistics?.revenueAmount
                                      ?.decimals || 18,
                                  ),
                                ) / 10
                              )
                                .toLocaleString('de-DE', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                  useGrouping: true,
                                })
                                .replace(/\./g, ' ')}
                              {')'}
                            </>
                          ) : (
                            0
                          )}
                        </RevenueValue>
                      </InfoTextWrapper>
                    </FormWrapper>
                  </GameStasItem>
                  <BIGAInfoWrapper>
                    <Spin
                      spinning={playerGameRevenueListLoading}
                      wrapperClassName="spin-revenue-table"
                    >
                      <InfoRightWrapperForScroll
                        isEmpty={!playerGameRevenueList?.length}
                      >
                        {playerGameRevenueList?.length ? (
                          playerGameRevenueList?.map(
                            (
                              { level, revenueAmount, gameName, gameId },
                              index,
                            ) => (
                              <>
                                <StyledDivider />
                                <GameDetailRow key={index}>
                                  <InfoTextWrapper className="game-name-wrapper">
                                    <InfoTitleText className="font-size-14px">
                                      {gameName}
                                    </InfoTitleText>
                                    <LevelWrapper>
                                      <span className="level">
                                        Level {level}
                                      </span>
                                      <span className="id">ID {gameId}</span>
                                    </LevelWrapper>
                                  </InfoTextWrapper>
                                  <InfoTextWrapper className="game-revenue-wrapper">
                                    <WinLabel>WIN</WinLabel>
                                    <BIGAValue className="game-revenue">
                                      {'+'}
                                      {revenueAmount?.amount
                                        ? (
                                            parseFloat(
                                              formatUnits(
                                                revenueAmount?.amount,
                                                revenueAmount?.decimals,
                                              ),
                                            ) / 10
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : 0}
                                      {' $'}
                                    </BIGAValue>
                                  </InfoTextWrapper>
                                </GameDetailRow>
                              </>
                            ),
                          )
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </InfoRightWrapperForScroll>

                      {playerGameRevenueList?.length <
                        playerGameRevenueListTotal && (
                        <LoadMoreButtonWrapper>
                          <span>Showing 5 items out of 250 results</span>
                          <ShowMoreButton
                            onClick={() => {
                              setParams({
                                ...params,
                                pageIndex: params.pageIndex + 1,
                              });
                            }}
                          >
                            <LoadMoreText>Show more</LoadMoreText>
                          </ShowMoreButton>
                        </LoadMoreButtonWrapper>
                      )}
                    </Spin>
                  </BIGAInfoWrapper>
                </RevenueHistoryWrapper>
              </InfoWrapper>
              {isEnableNotificateion && (
                <InfoWrapper>
                  <InfoTitle>Notifications Settings</InfoTitle>
                  <FormWrapper>
                    {notificationSettings.map(setting => (
                      <GameStasItem key={setting.type}>
                        <InfoTextWrapper>
                          <InfoTitleText className="font-weight-400-sm">
                            {getNotificationTitle(setting.type)}
                          </InfoTitleText>
                        </InfoTextWrapper>
                        <Switch
                          checked={setting.enabled}
                          onChange={checked =>
                            handleNotificationToggle(setting.type, checked)
                          }
                        />
                      </GameStasItem>
                    ))}
                  </FormWrapper>
                </InfoWrapper>
              )}
              <DeleteButtonWrapper>
                <DeleteButton onClick={showDeleteAccountModal}>
                  Delete account
                </DeleteButton>
              </DeleteButtonWrapper>

              <LogoutMobileWrapper
                onClick={() => setIsOpenLogoutModal(!isOpenLogoutModal)}
              >
                Logout <LogoutIcon />
              </LogoutMobileWrapper>
            </InfoRightWrapper>
          </ProfileInfoWrapper>
          <ConfirmDelete />
          <LogoutModal
            isOpen={isOpenLogoutModal}
            onCancel={() => setIsOpenLogoutModal(false)}
            onOk={logOut}
          />
        </ProfileContentWrapper>
        <Gradient1 />
        <Gradient2 />
        <Gradient3 />
        <ChangeWalletModal
          open={isOpenChangeWalletModal}
          onCancel={() => {
            onDisconnect();
            setIsOpenChangeWalletModal(false);
            setLoading(false);
            setIsMetamaskClick(false);
          }}
          onConfirm={onConfirmChangeWallet}
          loading={false}
        />
      </ProfileWrapper>
    </HomepageTemplate>
  );
};
