import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginWallet, logout } from 'redux/slices/user';
import { LocalhostStorage } from 'utils/sessionStorage';
import { useAccount, useConnect, useDisconnect } from 'wagmi';

export const defaultConnectedWalletStatus = {
  Injected: false,
  WalletConnect: false,
  WalletLink: false,
  Bitkeep: false,
};

function useWagmiConnectors() {
  const dispatch = useDispatch();
  const { connectors, connect } = useConnect();
  const { address, chainId: accountChainId } = useAccount();
  const { disconnect } = useDisconnect();

  const CHAIN_ID = LocalhostStorage.get('chainId');
  useEffect(() => {
    if (!CHAIN_ID)
      LocalhostStorage.set('chainId', +process.env.REACT_APP_CHAIN_ID);
  }, [CHAIN_ID]);

  useEffect(() => {
    if (address) {
      LocalhostStorage.set('account', address);
      LocalhostStorage.set('isConnected', 'true');
      LocalhostStorage.set('typeOfConnector', 'Metamask');
      LocalhostStorage.set('chainId', +accountChainId);
    } else {
      disconnect();
      LocalhostStorage.remove('isConnected');
      LocalhostStorage.remove('account');
      LocalhostStorage.remove('typeOfConnector');
    }
  }, [address]);

  const onConnectWallet = async ({
    onError,
  }: { onError?: () => void } = {}) => {
    const chainId = LocalhostStorage.get('chainId');
    const metaMaskConnector = connectors.find(
      (connector: any) => connector.name === 'MetaMask',
    );

    if (metaMaskConnector) {
      await connect(
        {
          chainId: chainId || CHAIN_ID,
          connector: metaMaskConnector,
        },
        {
          onSuccess: data => {
            dispatch(loginWallet(data.accounts[0]));
          },
          ...(onError && { onError }),
        },
      );
    }
  };

  const onDisconnect = async () => {
    try {
      disconnect();
      const chainId = LocalhostStorage.get('chainId');
      LocalhostStorage.remove('isConnected');
      LocalhostStorage.remove('account');
      LocalhostStorage.remove('chainId');
      LocalhostStorage.remove('typeOfConnector');
      LocalhostStorage.remove('path');
      LocalhostStorage.set('chainId', +chainId);
      dispatch(logout());
    } catch (error) {
      console.log({ error });
    }
  };

  return {
    onConnectWallet,
    onDisconnect,
  };
}

export default useWagmiConnectors;
