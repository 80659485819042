import { Spin, Tooltip } from 'antd';
import { useAccount } from 'wagmi';
import {
  ButtonWrapper,
  Description,
  LinkButton,
  StyledModal,
  SubmitButton,
} from './styled';

export const SignMessageToLoginModal = ({
  open,
  onCancel,
  onConfirm,
  loading,
}: {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { address: account } = useAccount();

  return (
    <>
      <Spin spinning={loading}>
        <StyledModal isOpen={open} width="500px" onCancel={onCancel}>
          <div>
            <Description>
              Your account is already connected to a wallet address{' '}
              <Tooltip title={account}>
                <b>
                  <span>{`${account?.substring(0, 6)}...${account?.substring(
                    38,
                  )}`}</span>
                </b>
              </Tooltip>
              . Please click on Confirm button and sign message to login
            </Description>

            <ButtonWrapper>
              <LinkButton onClick={onCancel}>Disconnect</LinkButton>
              <SubmitButton
                height="40px"
                width="150px"
                onClick={() => {
                  onConfirm();
                }}
              >
                Confirm
              </SubmitButton>
            </ButtonWrapper>
          </div>
        </StyledModal>
      </Spin>
    </>
  );
};
