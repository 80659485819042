import { Spin, Tooltip } from 'antd';
import { useAccount } from 'wagmi';
import {
  ButtonWrapper,
  Description,
  LinkButton,
  StyledModal,
  SubmitButton,
} from './styled';
import { useAppSelector } from 'redux/store';

export const ChangeWalletModal = ({
  open,
  onCancel,
  onConfirm,
  loading,
}: {
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  const { address: account } = useAccount();
  const { playerInfo } = useAppSelector(state => state.player);

  return (
    <StyledModal isOpen={open} width="500px" onCancel={onCancel}>
      <Spin spinning={loading}>
        <div>
          <Description>
            Your account is linked to the wallet address{' '}
            <Tooltip title={account}>
              {playerInfo && playerInfo.walletAddress && (
                <b>
                  <span>{`${playerInfo.walletAddress?.substring(
                    0,
                    6,
                  )}...${playerInfo.walletAddress?.substring(38)}`}</span>
                </b>
              )}
            </Tooltip>
            . If you want to change the wallet address , please click on Confirm
            button and sign message on the next step.
          </Description>

          <ButtonWrapper>
            <LinkButton onClick={onCancel}>Disconnect</LinkButton>
            <SubmitButton
              height="40px"
              width="150px"
              onClick={() => {
                onConfirm();
              }}
            >
              Confirm
            </SubmitButton>
          </ButtonWrapper>
        </div>
      </Spin>
    </StyledModal>
  );
};
