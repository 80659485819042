import { List, Select, Spin } from 'antd';
import { TokenItem } from 'components/Cashier/TokenItem';
import { useGetListPaymentToken } from 'hooks/cashier/useGetListPaymentToken';
import { useMemo, useState } from 'react';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { LocalhostStorage } from 'utils/sessionStorage';
import {
  networkTypeSelected,
  SUPPORTED_NETWORKS,
} from 'utils/wallets/connector';

import {
  ModalSelectTokenContainer,
  ModalSelectTokenFirstBlock,
  ModalSelectTokenSecondBlock,
  ModalSelectTokenWrapper,
  ModalWrapper,
  NetworkImage,
  SearchOutlinedStyled,
  SelectNetwork,
} from './styled';
import useWagmiConnectors from 'hooks/useWagmiConnectors';
import { useAccount, useSwitchChain } from 'wagmi';
import { toast } from 'react-toastify';
import { WalletNotConnected } from 'components/Cashier/WalletNotConnected';

const { Option } = Select;

export const SelectToken = ({ onItemSelected, account }) => {
  const open = useModalIsOpen(ApplicationModal.CASHIER_SELECT_TOKEN);
  const toggleModal = useToggleModal(ApplicationModal.CASHIER_SELECT_TOKEN);
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    toggleModal();
  };

  const handleItemSelected = item => {
    onItemSelected(item);
    handleCancel();
  };

  const { switchChain } = useSwitchChain();
  const { chainId } = useAccount();

  const networkType = useMemo(() => {
    if (!chainId) return;
    const network = Object.values(SUPPORTED_NETWORKS).find(
      network => network.chainId === chainId,
    );
    if (network) {
      return network.netType;
    } else {
      return;
    }
  }, [chainId]);

  const deposits = Object.values(SUPPORTED_NETWORKS).filter(
    network => network.netType === networkTypeSelected,
  );
  const defaultChainId = process.env.REACT_APP_CHAIN_ID;
  const defaultDepositName =
    deposits.find(dep => dep.chainId === LocalhostStorage.get('chainId'))
      ?.name ?? '';
  const defaultDepositChainId =
    deposits.find(dep => dep.chainId === LocalhostStorage.get('chainId'))
      ?.chainId ?? +defaultChainId;
  const [searchQuery, setSearchQuery] = useState('');
  const [params, setParams] = useState({
    pageSize: 20,
    pageIndex: 1,
    chainId: defaultDepositChainId,
  });
  const { onConnectWallet } = useWagmiConnectors();

  const [selectedDeposit, setSelectedDeposit] = useState(defaultDepositName);

  const {
    listPaymentToken,
    loading: listPaymentTokenLoading,
    total: listPaymentTokenTotal,
  } = useGetListPaymentToken(params);

  const handleSelect = deposit => {
    try {
      if (!deposit) return;
      setLoading(true);
      const getSelectedChainId = deposits.find(
        dep => dep.name === deposit,
      ).chainId;
      switchChain(
        { chainId: getSelectedChainId },
        {
          onError: () => {
            toast.error('The user rejected switching the network.');
            setSelectedDeposit(selectedDeposit);
            setLoading(false);
          },
          onSuccess: () => {
            LocalhostStorage.set('chainId', getSelectedChainId);
            setParams(prev => ({
              ...prev,
              chainId: getSelectedChainId,
            }));
            setLoading(false);
          },
        },
      );
      setSelectedDeposit(deposit);
    } catch (err) {
      console.log('Change network error ', err);
    }
  };

  return (
    <ModalWrapper
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      width="528px"
    >
      <ModalSelectTokenWrapper>
        {account ? (
          <ModalSelectTokenContainer>
            <ModalSelectTokenFirstBlock>
              Select a cryptocurrency
            </ModalSelectTokenFirstBlock>

            <ModalSelectTokenSecondBlock
              placeholder="Search for a cryptocurrency"
              allowClear
              addonBefore={<SearchOutlinedStyled />}
              onChange={e => setSearchQuery(e.target.value)}
            />
            <Spin spinning={loading}>
              <SelectNetwork
                value={selectedDeposit}
                onChange={e => {
                  handleSelect(e);
                }}
                style={{ width: '12rem' }}
              >
                {deposits?.map(deposit => (
                  <Option key={deposit.chainId} value={deposit.name}>
                    <NetworkImage
                      style={{ width: '1rem', height: '1rem' }}
                      src={deposit?.iconUrl}
                    />
                    {deposit.name}
                  </Option>
                ))}
              </SelectNetwork>
            </Spin>
            <List
              dataSource={
                listPaymentToken
                  ? listPaymentToken.filter(
                      item =>
                        item.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        item.symbol
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()),
                    )
                  : []
              }
              renderItem={item => (
                <TokenItem
                  item={item}
                  handleItemSelected={handleItemSelected}
                />
              )}
              loading={listPaymentTokenLoading}
            />
          </ModalSelectTokenContainer>
        ) : (
          <WalletNotConnected />
        )}
      </ModalSelectTokenWrapper>
    </ModalWrapper>
  );
};
