import { LoadingOutlined } from '@ant-design/icons';
import { Form, Spin, Tooltip } from 'antd';
import carvIcon from 'assets/images/carv_id.png';
import metaMaskIcon from 'assets/logo-metamask.png';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import {
  BigaLogoWrapper,
  CloseIconWrapper,
  CustomFormItem,
  FormWrapper,
  GradientTop,
  RegisterConnectButton,
  RegisterConnectButtonsWrapper,
  RegisterStepButton,
  RegisterStepButtonWrapper,
  RegisterStepDescription,
  RegisterStepLink,
  RegisterStepTitle,
} from 'components/Register/styled';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser } from 'redux/slices/player';
import { login, loginByWallet, oneTimeTokenForMiniApp } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';
import {
  ForgotPasswordLinkWrapper,
  ForgotPasswordStepLink,
  LoginConnectText,
  LoginLinkWrapper,
  LoginWrapper,
} from './styled';
import { Input } from 'components/Elements/Input';
import { ReactComponent as MailIcon } from 'assets/icons/mail-icon.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/password.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye-show.svg';
import { ReactComponent as EyeOffIcon } from 'assets/icons/eye-off.svg';
import gradient from 'assets/images/register/gradient.svg';
import useWagmiConnectors from 'hooks/useWagmiConnectors';
import { useAccount, useSignMessage } from 'wagmi';
import { SignMessageToLoginModal } from 'components/Modals/SignMessageToLogin';

export const LoginForm = () => {
  const navigate = useNavigate();

  const [inputPass, setInputPass] = useState<string>();
  const [emailAddress, setEmailAddress] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [metaMaskLoading, setMetaMaskLoading] = useState<boolean>(false);
  const [byWallet, setByWallet] = useState<boolean>(false);
  const [showSignMessageModal, setShowSignMessageModal] =
    useState<boolean>(false);

  const [typeOfConnector, setTypeOfConnector] = useState<string>();
  const { address: account } = useAccount();
  const { onConnectWallet, onDisconnect } = useWagmiConnectors();

  const dispatch = useDispatch();
  const { signMessage: signMessageWagmi } = useSignMessage();
  const [searchParams, setSearchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState(false);

  let bot: boolean | undefined;
  let telegramId: number | undefined;
  let chatId: number | undefined;
  const botParam: string = searchParams.get('bot');
  const telegramIdParam: string = searchParams.get('telegramId');
  const chatIdParam: string = searchParams.get('chatId');
  if (botParam) {
    bot = botParam === 'true';
  }
  if (telegramIdParam) {
    telegramId = parseInt(telegramIdParam);
  }
  if (chatIdParam) {
    chatId = parseInt(chatIdParam);
  }
  if (bot) {
    LocalhostStorage.remove('accessToken');
    LocalhostStorage.remove('refreshToken');
    LocalhostStorage.remove('tokenType');
  }

  const onSignMessageSuccess = async (data: any, variables: any) => {
    const loginRes = await loginByWallet({
      walletAddress: account,
      signature: data,
      message: String(variables?.message),
      telegramId: telegramId,
    });
    setMetaMaskLoading(false);
    setByWallet(false);
    if (loginRes?.success) {
      LocalhostStorage.set('accessToken', loginRes?.payload?.data?.accessToken);
      LocalhostStorage.set(
        'refreshToken',
        loginRes?.payload?.data?.refreshToken,
      );
      LocalhostStorage.remove('tokenType');
      dispatch(loginUser(loginRes?.payload?.data));

      if (loginRes?.payload?.data?.status === PlayerStatus.UploadedProfile) {
        navigate(PitRouter.GAME_LIST);
      } else {
        navigate(PitRouter.REGISTER);
      }
    } else {
      setMetaMaskLoading(false);
      setByWallet(false);
      toast.error(loginRes?.message || 'Login failed');
      onDisconnect();
    }
  };

  const handleLoginByWallet = useCallback(async () => {
    if (byWallet && account && typeOfConnector) {
      if (typeOfConnector === 'Metamask') {
        setShowSignMessageModal(true);
      } else {
        setMetaMaskLoading(false);
        setByWallet(false);
        onDisconnect();
        return;
      }
    }
  }, [byWallet, account, typeOfConnector]);

  const onConfirm = async () => {
    setShowSignMessageModal(false);
    await signMessageWagmi(
      {
        account: account,
        message: `If you click the 'sign' button, you agree to authorize us to make this transaction.
      Your Wallet Address: ${account}
      Timestamp: ${Date.now()}
     `,
      },
      {
        onSuccess: onSignMessageSuccess,
        onError: () => {
          toast.error('User denied message signature.');
          setMetaMaskLoading(false);
          setByWallet(false);
          onDisconnect();
          return;
        },
      },
    );
  };

  useEffect(() => {
    handleLoginByWallet();
  }, [handleLoginByWallet]);

  const onCancel = () => {
    setMetaMaskLoading(false);
    setByWallet(false);
    onDisconnect();
    setShowSignMessageModal(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const loginRes = await login({
      emailAddress,
      password: inputPass,
      telegramId: telegramId,
      chatId: chatId,
    });
    if (loginRes?.success) {
      LocalhostStorage.set('accessToken', loginRes?.payload?.data?.accessToken);
      LocalhostStorage.set(
        'refreshToken',
        loginRes?.payload?.data?.refreshToken,
      );
      LocalhostStorage.remove('tokenType');

      if (bot) {
        try {
          const { expiresIn, token } = await oneTimeTokenForMiniApp({
            telegramId: telegramId,
            chatId: chatId,
          });
          window.location.replace(
            'tg://resolve?domain=' +
              process.env.REACT_APP_BOT_LOGIN_CALLBACK +
              '&startapp=' +
              token,
          );
        } catch (e) {
          toast.error(e?.message || 'Get one time token failed');
        }

        setLoading(false);
        return;
      }

      dispatch(loginUser(loginRes?.payload?.data));
      if (loginRes?.payload?.data?.status === PlayerStatus.UploadedProfile) {
        navigate(PitRouter.GAME_LIST);
      } else {
        navigate(PitRouter.REGISTER);
      }
      setLoading(false);
    } else {
      toast.error(loginRes?.message || 'Login failed');
      setLoading(false);
    }
  };

  const onMetaMaskClick = async () => {
    setMetaMaskLoading(true);
    setByWallet(true);
    await onConnectWallet({ onError: () => setMetaMaskLoading(false) });
    setTypeOfConnector('Metamask');
  };

  const carvLogin = () => {
    const currentTimestamp = Date.now();
    const carvAuthUrl = `https://auth.carv.io/auth/authorize?client_id=${process.env.REACT_APP_CARV_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_CARV_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_CARV_SCOPE}&state=${currentTimestamp}`;
    window.location.href = carvAuthUrl;
  };

  return (
    <LoginWrapper>
      <BigaLogoWrapper onClick={() => navigate(PitRouter.HOME)} />
      <RegisterStepTitle>Welcome Back</RegisterStepTitle>
      <LoginLinkWrapper>
        <RegisterStepDescription>
          Don’t have an account?
        </RegisterStepDescription>
        <RegisterStepLink
          disabled={loading}
          onClick={() => {
            if (bot) {
              navigate(
                PitRouter.REGISTER +
                  `?bot=true&telegramId=${telegramId}&chatId=${chatId}`,
              );
            } else {
              navigate(PitRouter.REGISTER);
            }
          }}
        >
          Sign Up!
        </RegisterStepLink>
      </LoginLinkWrapper>
      <FormWrapper>
        <Form
          className="login-form"
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
          disabled={loading}
        >
          <CustomFormItem
            name="email"
            rules={[
              {
                pattern: new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'),
                message: 'Invalid email',
              },
            ]}
          >
            <Input
              prefix={<MailIcon />}
              onChange={e => setEmailAddress(e.target.value)}
              required
              placeholder="Email address"
              onKeyDown={e => e.keyCode === 32 && e.preventDefault()}
            />
          </CustomFormItem>
          <Form.Item name="password">
            <Input
              onChange={e => setInputPass(e.target.value)}
              type={passwordVisible ? 'text' : 'password'}
              placeholder="Password"
              prefix={<PasswordIcon />}
              required
              suffix={
                passwordVisible ? (
                  <EyeIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    className="fill-white"
                  />
                ) : (
                  <EyeOffIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  />
                )
              }
            />
          </Form.Item>
          <ForgotPasswordLinkWrapper>
            <ForgotPasswordStepLink
              disabled={loading}
              onClick={() => {
                navigate(PitRouter.FORGOT_PASSWORD);
              }}
            >
              Forgot Password?
            </ForgotPasswordStepLink>
          </ForgotPasswordLinkWrapper>
          <RegisterStepButtonWrapper>
            <RegisterStepButton
              htmlType="submit"
              loading={loading}
              disabled={!emailAddress || !inputPass}
            >
              Log In
            </RegisterStepButton>
          </RegisterStepButtonWrapper>
        </Form>
      </FormWrapper>

      <LoginConnectText>or login with</LoginConnectText>

      <RegisterConnectButtonsWrapper>
        <RegisterConnectButton disabled={loading} onClick={() => carvLogin()}>
          <img src={carvIcon} alt="carv-icon" />
          <span>CARV ID</span>
        </RegisterConnectButton>
        <RegisterConnectButton
          className="metamask-btn"
          disabled={metaMaskLoading}
          onClick={onMetaMaskClick}
        >
          <Spin
            spinning={
              metaMaskLoading && byWallet && typeOfConnector === 'Metamask'
            }
          />
          <img src={metaMaskIcon} alt="metamask-icon" />
          <span>METAMASK</span>
        </RegisterConnectButton>
        <Tooltip title="Coming soon">
          <RegisterConnectButton disabled>
            <img src={geminiIcon} alt="gemini-icon" />
            <span>GEMINI</span>
          </RegisterConnectButton>
        </Tooltip>
      </RegisterConnectButtonsWrapper>
      <GradientTop src={gradient} />
      <CloseIconWrapper onClick={() => navigate('..')} />
      <SignMessageToLoginModal
        open={showSignMessageModal}
        onCancel={onCancel}
        onConfirm={onConfirm}
        loading={false}
      />
    </LoginWrapper>
  );
};
