import { useEffect, useState } from 'react';
import { getSecurityFlagList } from 'services/api';

export interface ISecurityFlagList {
  playerId: string;
  username: string;
  avatarURL?: string;
}

export const useGetSecurityFlagList = (params, refresh: boolean) => {
  const [securityFlagList, setSecurityFlagList] =
    useState<ISecurityFlagList[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getSecurityFlagList(params);
      if (res?.success) {
        setSecurityFlagList(res?.payload?.data?.data);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { securityFlagList, total, loading };
};
