import { useEffect, useState } from 'react';
import { getSecurityFlagByPlayerId } from 'services/api';

export interface ISecurityFlagDetail {
  _id: string;
  sessionId: string;
  gameId: string;
  createdAt: string;
  name: string;
  level: string;
}

export type SecurityFlagDetailParams = {
  pageSize: number;
  pageIndex: number;
  sortField: string;
  sortDirection: string;
};

export const useGetSecurityFlagByPlayerId = (
  playerId: string,
  params: SecurityFlagDetailParams,
  refresh: boolean,
) => {
  const [securityFlagList, setSecurityFlagList] =
    useState<ISecurityFlagDetail[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (playerId) {
      void (async () => {
        setLoading(true);
        const res = await getSecurityFlagByPlayerId(playerId, params);
        if (res?.success) {
          setSecurityFlagList(res?.payload?.data?.data);
          setTotal(res?.payload?.data?.totalCount);
        }
        setLoading(false);
      })();
    }
  }, [params, refresh, playerId]);
  return { securityFlagList, total, loading };
};
