import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  ButtonWrapper,
  CryptoWithdrawalGradientLine,
  EmailText,
  EmailWrapper,
  ModalTitle,
  ModalWithdrawContainer,
  ModalWithdrawContent,
  ModalWithdrawWrapper,
  ModalWrapper,
  SubmitButton,
  Text,
} from './styled';

export const FiatDepositConfirmation = () => {
  const open = useModalIsOpen(ApplicationModal.FIAT_DEPOSIT_CONFIRMATION);
  const toggleModal = useToggleModal(
    ApplicationModal.FIAT_DEPOSIT_CONFIRMATION,
  );
  const handleCancel = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('success');
    window.history.replaceState({}, '', url.toString());
    toggleModal();
  };

  return (
    <ModalWrapper isOpen={open} onCancel={handleCancel} width="520px">
      <ModalWithdrawWrapper>
        <ModalWithdrawContainer>
          <ModalTitle>Congratulations!</ModalTitle>
          <ModalWithdrawContent>
            <Text>
              Your deposit is complete! You will receive a confirmation email
              from us verifying your payment, and your credits will be credited
              to your account soon.
            </Text>
            <EmailWrapper>
              Having issues or haven&apos;t received your credits? <br />
              Please email us at{' '}
              <EmailText to="mailto:cashier@bigarcade.org">
                cashier@bigarcade.org
              </EmailText>
              .
            </EmailWrapper>
          </ModalWithdrawContent>
          <ButtonWrapper>
            <SubmitButton onClick={handleCancel}>Done</SubmitButton>
          </ButtonWrapper>
        </ModalWithdrawContainer>
      </ModalWithdrawWrapper>
      <CryptoWithdrawalGradientLine />
    </ModalWrapper>
  );
};
