import { DeleteOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { useState } from 'react';

import {
  ActionWrapper,
  AdminPanelTable,
  BodyContentWrapper,
  ButtonInTable,
  StyledPagination,
  TableContentWrapper,
  UserCellWrapper,
} from './styled';
import { useGetSecurityFlagList } from 'hooks/security-flag/useGetSecurityFlagList';
import { updateFlagStatus } from 'services/api';
import { toast } from 'react-toastify';
import { SecurityFlagDetail } from 'components/Modals/SecurityFlag';
import { useToggleModal } from 'redux/slices/application/hook';
import { ApplicationModal } from 'redux/slices/application';

export const SecurityFlagTable = () => {
  const [selectedUser, setSelectedUser] = useState('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const defaultPageSize = 50;
  const [params, setParams] = useState({
    pageSize: defaultPageSize,
    pageIndex: 1,
  });
  const [refresh, setRefresh] = useState<boolean>(false);

  const {
    loading: whitelistLoading,
    securityFlagList,
    total,
  } = useGetSecurityFlagList(params, refresh);

  const toggleModal = useToggleModal(ApplicationModal.SECURITY_FLAG_DETAIL);

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: value => params.pageSize * (currentPage - 1) + value + 1,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (username, record) => (
        <UserCellWrapper>
          <img src={record?.avatarURL || defaultAvatarImg} />
          <div>{username}</div>
        </UserCellWrapper>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      className: 'action',
      render: (_, record, index) => (
        <ActionWrapper>
          <ButtonInTable
            onClick={() => {
              setSelectedUser(record.playerId);
              toggleModal();
            }}
          >
            Detail
          </ButtonInTable>
        </ActionWrapper>
      ),
    },
  ];

  return (
    <TableContentWrapper>
      <BodyContentWrapper>
        <AdminPanelTable
          dataSource={securityFlagList?.map((item, key) => ({ ...item, key }))}
          columns={columns}
          pagination={false}
          loading={whitelistLoading}
        />
        <Row justify="center" className="mb-5">
          {total > defaultPageSize && (
            <StyledPagination
              className="mt-4"
              pageSize={defaultPageSize}
              current={currentPage}
              onChange={page => {
                setCurrentPage(page);
                setParams({
                  ...params,
                  pageIndex: page,
                });
              }}
              total={total}
              showSizeChanger={false}
            />
          )}
        </Row>
      </BodyContentWrapper>
      <SecurityFlagDetail
        playerId={selectedUser}
        setRefreshFlaggedPlayer={setRefresh}
        refreshFlaggedPlayer={refresh}
      />
    </TableContentWrapper>
  );
};
