import { http, createConfig } from 'wagmi';
import {
  base,
  bsc,
  mainnet,
  avalanche,
  sepolia,
  avalancheFuji,
  bscTestnet,
  baseSepolia,
} from 'wagmi/chains';
import { injected, metaMask, safe } from 'wagmi/connectors';

export const config = createConfig({
  chains: [
    mainnet,
    base,
    bsc,
    avalanche,
    sepolia,
    avalancheFuji,
    bscTestnet,
    baseSepolia,
  ],
  connectors: [injected(), metaMask(), safe()],
  transports: {
    [mainnet.id]: http(),
    [base.id]: http(),
    [bsc.id]: http(),
    [sepolia.id]: http(),
    [avalancheFuji.id]: http(),
    [bscTestnet.id]: http(),
    [avalanche.id]: http(),
    [baseSepolia.id]: http(),
  },
});
