import { Button, Checkbox, Modal } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: #15151b;
    border-radius: 24px;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-close-icon {
    svg {
      fill: #fff;
    }
  }
`;

export const ModalLevelMasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BodyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyModalInfoWrapper = styled.div`
  display: flex;
  padding: 32px 0px 20px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  ${media.sm} {
    padding: 32px 0px 20px;
  }
`;

export const ModalInfoWrapper = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;

  ${media.sm} {
    font-size: 20px;
  }
`;

export const BodyModalContentWrapper = styled.div<{
  isEmpty?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 40px 34px 40px;

  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  max-height: 100%;
  height: ${props => (props.isEmpty ? '100%' : 'auto')};

  ${media.sm} {
    margin: 15px 10px 34px 10px;
  }
`;

export const ContentItemLeftValue = styled(Text)`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;

  ${media.sm} {
    font-weight: 400;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  border-radius: 50px;
  font-weight: 500;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border: none;
  color: #fff;
  display: flex;
  width: 100px;
  height: 40px;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;

  ${media.sm} {
    font-size: 16px;
  }
`;

export const ButtonInTable = styled(StyledButton)`
  font-weight: 400;
  height: 30px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 30px;
`;

export const ShowMoreText = styled.div`
  cursor: pointer;
  color: #00aafd;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner:after {
    transform: rotate(45deg) scale(1) translate(-95%, -50%);
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;
